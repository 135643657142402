/**
 * 
 */

var _timmer_c = 120;
var _timmer;
function sendMessage()
{
	var phone = $('#phone').val();
	if(!phone)
	{
		$('#phone').parent().addClass('has-error');
		return false;
	}
	$.get('/auth/sendMessage',{phone:phone},function(data){
		data = $.parseJSON(data);
		if(data.code != '10000')
		{
			alert(data.msg);
		}else{
			timeC();
		}
	});
}

function timeC()
{
  $('#btn_vcode').html('获取验证码('+_timmer_c+')');
  _timmer_c--;
  _timmer = setTimeout("timeC()",1000);
  if(_timmer_c<=0)
  {
	  clearTimeout(_timmer);
	  $('#btn_vcode').html('重新获取验证码');
  }
}